import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  IonPage,
  useIonAlert,
  useIonLoading,
  useIonToast,
  IonRow,
  IonCol,
  IonCardContent,
  IonTextarea,
  IonCardHeader,
  useIonModal,
  IonTitle,
} from '@ionic/react';
import React, { useState} from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, menuSharp, checkmarkCircleOutline, closeCircleOutline, createOutline, warningOutline, arrowBackOutline, personCircleOutline, documentTextOutline, carOutline, logoWhatsapp} from "ionicons/icons";
import { RestApi } from '../../services/Api';
import { Rupiah, getNameDate, getRoleMenu } from '../../services/Library';
import { So } from "../../models/Modal";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import { Browser } from '@capacitor/browser';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<So> = (props) => {
  const [so, setSo] = useState<any>([]);
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [loaded, setLoaded] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "so");
  const [presentToast] = useIonToast();
  const status: any = [
    {
      name: t("open"),
      color: "success",
    },
    {
      name: t("done"),
      color: "danger",
    },
    {
      name: t("check"),
      color: "secondary",
    },
    {
      name: t("wait-approve"),
      color: "primary",
    },
    {
      name: t("cancel"),
      color: "warning",
    },
  ];

  const statusDelivery: any = [
    {
      name: t("no"),
      color: "success",
    },
    {
      name: t("yes"),
      color: "danger",
    },
  ];

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getSo(props.idSo);
    event.detail.complete();
  };

  const processAlert = (status : number, field: string) => {
    alert({
      header: `${t("description")} ${t(field)}?`,
      cssClass: "header-alert",
      buttons: [
        {
          text: "X",
          cssClass: "alert-button-close",
          role: "close",
        },
        {
          text: "Batal",
          cssClass: "alert-button-cancel",
          role: "cancel",
        },
        {
          text: field,
          cssClass: "alert-button-confirm",
          role: "confirm",
        },
      ],
      inputs: [
        {
          placeholder: "Note",
          type: "textarea",
          name: `note`,
        },
      ],
      onDidDismiss: async (e: any) => {
        if (e.detail.role === "confirm") {
          if (!e.detail.data.values.note) {
            presentToast({
              message: t("required"),
              duration: 1500,
              position: "middle",
              icon: warningOutline,
              color: "danger",
            });
            return;
          } else {
            await sendConfirm(status, e.detail.data.values.note, field);
          }
        } else if (e.detail.role === "cancel") {
          if (!e.detail.data.values.note) {
            presentToast({
              message: t("required"),
              duration: 1500,
              position: "middle",
              icon: warningOutline,
              color: "danger",
            });
            return;
          } else {
            await sendConfirm(4, e.detail.data.values.note, field);
          }
        }
      },
    });
  };

  const sendConfirm = async (status: Number, note: string, field: string) => {
    await presentLoading({ message: t("loading") });
    await RestApi(`so/update-check-approve/${so.so_id}`, "post", {
      status: status,
      note: note,
      field: field,
    })
      .then(async (res: any) => {
        if (res.data.code === 200) {
          getSo(props.idSo);
          presentToast({
            message: res.data.message,
            duration: 1500,
            icon: checkmarkCircleOutline,
            color: "success",
          });
          dismissLoading();
        } else {
          presentToast({
            message: res.data.message,
            duration: 1500,
            icon: closeCircleOutline,
            color: "danger",
          });
          dismissLoading();
        }
      })
      .catch((error: any) => {
        presentToast({
          message: error.message,
          duration: 1500,
          icon: closeCircleOutline,
          color: "danger",
        });
        dismissLoading();
      });
  };

  const getSo = (id: string) => {
    if (!id) {
      props.onDismiss();
      return;
    }
    setLoaded(false);
    RestApi(`so/${id}`, "get", {}, "application/json")
      .then(async (res: any) => {
        if (res.data.code === 200) {
          setSo(res.data.data);
          setLoaded(true);
        } else {
          props.onDismiss();
        }
      })
      .catch((err: any) => {
        props.onDismiss();
        massageAlert("error", err.message);
      });
  };

  const confirmCustomer = () => {
    RestApi(`so/signed-confirm/${props.idSo}`, "get", {}, "application/json")
      .then(async (res: any) => {
        if (res.data.code === 200) {
          await Browser.open({ url: res.data.data }); 
        } else {
          massageAlert("error", res.data.message);
          dismissLoading();
        }
      })
      .catch((err: any) => {
        props.onDismiss();
        massageAlert("error", err.message);
      });
  }

  const handleDismissForm = () => {
    getSo(props.idSo);
    dismissForm();
  };

  const [presentForm, dismissForm] = useIonModal(Form, {
    idSo: props.idSo,
    type: "edit",
    onDismiss: handleDismissForm,
  });

  useConstructor(() => {
    getSo(props.idSo);
  });

  return (
    <IonPage className="detail-content">
      <IonHeader className="ion-no-padding ion-no-margin">
        {loaded ? (
          <IonToolbar class="toolbar-detail-customer-profile ion-no-padding ion-no-margin">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismiss()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding ion-no-margin" lines="none">
              <IonTitle className="ion-no-padding ion-no-margin" color="light">
                <p className="ion-no-padding ion-no-margin">
                  <strong className="ion-no-padding ion-no-margin">
                    {so.so_customer_name} ({so.so_customer_id})
                  </strong>
                </p>
                <p className="ion-no-padding ion-no-margin">
                  {so.customer_address.customer_address_name} : 
                  {", " + so.customer_address.customer_address}
                </p>
              </IonTitle>
              <IonBadge slot="end" color={status[so.so_is_closed].color}>
                {status[so.so_is_closed].name}
              </IonBadge>
            </IonItem>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p className="ion-no-padding ion-no-margin">
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p className="ion-no-padding ion-no-margin">
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {so && loaded ? (
          <>
            <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <p className="ion-no-padding ion-no-margin">
                    <IonIcon
                      size="small"
                      className="ion-no-padding ion-no-margin"
                      src={carOutline}
                    />
                    Pengiriman ?{" "}
                    <IonBadge
                      slot="end"
                      color={statusDelivery[so.so_has_delivery].color}
                    >
                      {statusDelivery[so.so_has_delivery].name}
                    </IonBadge>
                  </p>
                  <p className="ion-no-padding ion-no-margin">
                    <IonIcon
                      size="small"
                      className="ion-no-padding ion-no-margin"
                      src={personCircleOutline}
                    />
                    {so.salesman_name}
                  </p>
                </IonText>
                <IonText
                  className="ion-no-padding ion-no-margin ion-text-end"
                  slot="end"
                >
                  <p className="ion-no-padding ion-no-margin">
                    <strong>{so.so_form_id}</strong>
                  </p>
                  <p className="ion-no-padding ion-no-margin">
                    <IonIcon
                      size="small"
                      style={{
                        marginRight: "2px",
                      }}
                      src={documentTextOutline}
                    />
                    <strong>{so.so_id}</strong>
                  </p>
                </IonText>
              </IonItem>
              <IonCardContent className="ion-no-padding ion-no-margin">
                <IonItem lines="none">
                  <IonText className="ion-no-padding ion-no-margin">
                    <p className="ion-no-padding ion-no-margin">
                      {so.term_name}
                    </p>
                    <p className="ion-no-padding ion-no-margin">
                      {so.term_days} {t("day")}
                    </p>
                  </IonText>
                  <IonText slot="end" className="ion-no-padding ion-text-end">
                    <p className="ion-no-padding ion-no-margin">
                      Tanggal : {getNameDate(so.so_date)}
                    </p>
                    <p className="ion-no-padding ion-no-margin">
                      Tgl. Perkiraan : {getNameDate(so.so_expected_date)}
                    </p>
                  </IonText>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <br />
            <IonCard className="ion-no-padding ion-no-margin">
              <IonCardHeader className="ion-no-padding ion-margin-start">
                <IonText color={"dark"}>
                  <strong>Detail {t("product")}</strong>
                </IonText>
              </IonCardHeader>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {so.sodet.map((item: any, key: number) => (
                  <IonCard style={{ marginTop: "5px" }} key={key}>
                    <IonRow className="ion-no-padding ion-no-margin">
                      <IonCol
                        size="12"
                        className="ion-no-padding ion-no-margin"
                      >
                        <IonItem className="ion-no-padding ion-no-margin">
                          <IonText className="ion-no-padding ion-no-margin">
                            <p style={{ paddingLeft: "5px" }}>
                              <strong>
                                {item.sodet_item_name} ({item.sodet_item_id})
                              </strong>
                            </p>
                            <p style={{ paddingLeft: "5px" }}>
                              {`${item.sodet_item_quantity} x ${Rupiah(
                                Number(item.sodet_item_base_price)
                              )} `}
                              {item.sodet_disc_text !== "" && (
                                <IonBadge color="danger">
                                  <strong>{item.sodet_disc_text}</strong>
                                  {item.sodet_disc_cash_amount > 0 && (
                                    <strong>
                                      {` +  ${Rupiah(
                                        item.sodet_disc_cash_amount
                                      )}`}
                                    </strong>
                                  )}
                                </IonBadge>
                              )}
                            </p>
                          </IonText>
                        </IonItem>
                      </IonCol>
                      <IonCol
                        size="12"
                        className="ion-no-padding ion-no-margin"
                      >
                        <IonItem
                          className="ion-no-padding ion-no-margin"
                          lines="none"
                        >
                          <IonText
                            className="ion-no-padding ion-no-margin ion-text-end"
                            color={"dark"}
                            slot="end"
                            style={{
                              margin: "3px",
                            }}
                          >
                            <span>
                              {t("Subtotal")} :{" "}
                              <strong>{Rupiah(item.sodet_subtotal)}</strong>
                            </span>
                          </IonText>
                        </IonItem>
                      </IonCol>
                      <IonCol
                        size="5"
                        className="ion-no-padding ion-no-margin"
                      ></IonCol>
                    </IonRow>
                  </IonCard>
                ))}
              </IonCardContent>
            </IonCard>
            <br />
            <IonCard className="ion-no-padding ion-no-margin">
              <IonCardHeader className="ion-no-padding ion-margin-start">
                <IonText color={"dark"}>
                  <strong>{t("list") + " " + t("bill")}</strong>
                </IonText>
              </IonCardHeader>
              <IonCardContent className="ion-no-padding ion-margin-start">
                <IonItem className="ion-no-padding ion-no-margin">
                  <IonText color={"dark"} className="ion-text-left">
                    <p className="ion-no-padding ion-no-margin">Sub Total</p>
                    <p className="ion-no-padding ion-no-margin">
                      Dasar Pengenaan Pajak
                    </p>
                    <p className="ion-no-padding ion-no-margin">
                      {so.so_tax_rate
                        ? `PPn ${so.so_tax_rate}%`
                        : "Non Taxable"}
                    </p>
                  </IonText>
                  <IonText slot="end" className="ion-text-right">
                    <p className="ion-no-padding ion-no-margin">
                      {Rupiah(so.so_subtotal_amount)}
                    </p>
                    <p className="ion-no-padding ion-no-margin">
                      {Rupiah(so.so_base_amount)}
                    </p>
                    <p className="ion-no-padding ion-no-margin">
                      {Rupiah(so.so_tax_rounded_amount)}
                    </p>
                  </IonText>
                </IonItem>
                <IonItem className="ion-no-padding ion-no-margin">
                  <IonText color={"dark"} className="ion-text-left">
                    <strong>Total {t("bill")}</strong>
                  </IonText>
                  <IonText color={"dark"} slot="end">
                    <strong>{Rupiah(so.so_total_amount)}</strong>
                  </IonText>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <br />
            <IonCard className="ion-no-padding ion-no-margin">
              <IonCardContent className="ion-no-padding ion-margin-start">
                <IonItem className="ion-no-padding ion-no-margin" lines="none">
                  <IonTextarea
                    labelPlacement="floating"
                    placeholder={"-"}
                    label={t("note") ?? ""}
                    name="note"
                    readonly={true}
                    value={so.so_remark}
                  ></IonTextarea>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <br />
            <IonCard className="ion-no-padding ion-no-margin">
              <IonCardHeader className="ion-no-padding ion-margin-start">
                <IonText color={"dark"}>
                  <strong>{`${t("list")} Pengiriman`}</strong>
                </IonText>
              </IonCardHeader>

              <IonCardContent className="ion-no-padding ion-no-margin">
                {so.dordet.length ? (
                  so.dordet.map((item: any, key: number) => (
                    <IonCard style={{ marginTop: "5px" }} key={key}>
                      <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonItem className="ion-no-padding ion-no-margin">
                            <IonText className="ion-no-padding ion-no-margin">
                              <p style={{ paddingLeft: "5px" }}>
                                <strong>
                                  {item.dordet_item_name} ({item.dordet_item_id}
                                  )
                                </strong>
                              </p>
                              <p style={{ paddingLeft: "5px" }}>
                                {item.dordet_item_unit}
                              </p>
                            </IonText>
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              slot="end"
                            >
                              <p style={{ paddingLeft: "5px" }}>
                                {`${item.dor_id} `}
                              </p>
                              <p style={{ paddingLeft: "5px" }}>
                                {getNameDate(item.dor_date)}
                              </p>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonItem
                            className="ion-no-padding ion-no-margin"
                            lines="none"
                          >
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              color={"dark"}
                              style={{
                                margin: "3px",
                              }}
                            >
                              <span>
                                <IonIcon
                                  size="small"
                                  className="ion-no-padding ion-no-margin"
                                  src={carOutline}
                                />
                                {item.warehouse_name}
                              </span>
                            </IonText>
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              color={"dark"}
                              slot="end"
                              style={{
                                margin: "3px",
                              }}
                            >
                              <span>
                                {t("Qty")} :{" "}
                                <strong>
                                  {Number(item.dordet_item_quantity)}
                                </strong>
                              </span>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="5"
                          className="ion-no-padding ion-no-margin"
                        ></IonCol>
                      </IonRow>
                    </IonCard>
                  ))
                ) : (
                  <IonCard>
                    <IonText className="ion-text-center">
                      <strong>Pengiriman {t("not-found")}</strong>
                    </IonText>
                  </IonCard>
                )}
              </IonCardContent>
            </IonCard>
            <br />
            <IonCard className="ion-no-padding ion-no-margin">
              <IonCardHeader className="ion-no-padding ion-margin-start">
                <IonText color={"dark"}>
                  <strong>{`${t("list")} ${t("invoice")}`}</strong>
                </IonText>
              </IonCardHeader>

              <IonCardContent className="ion-no-padding ion-no-margin">
                {so.sidet.length ? (
                  so.sidet.map((item: any, key: number) => (
                    <IonCard style={{ marginTop: "5px" }} key={key}>
                      <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonItem className="ion-no-padding ion-no-margin">
                            <IonText className="ion-no-padding ion-no-margin">
                              <p style={{ paddingLeft: "5px" }}>
                                <strong>
                                  {item.sidet_item_name} ({item.sidet_item_id})
                                </strong>
                              </p>
                              <p style={{ paddingLeft: "5px" }}>
                                {item.sidet_item_unit}
                              </p>
                            </IonText>
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              slot="end"
                            >
                              <p style={{ paddingLeft: "5px" }}>
                                {`${item.si_id} `}
                              </p>
                              <p style={{ paddingLeft: "5px" }}>
                                {getNameDate(item.si_date)}
                              </p>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonItem
                            className="ion-no-padding ion-no-margin"
                            lines="none"
                          >
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              color={"dark"}
                              style={{
                                margin: "3px",
                              }}
                            >
                              <span>
                                {t("Qty")} :{" "}
                                <strong>
                                  {Number(item.sidet_item_quantity)}
                                </strong>
                              </span>
                            </IonText>
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-end"
                              color={"dark"}
                              slot="end"
                              style={{
                                margin: "3px",
                              }}
                            >
                              <span>
                                Subtotal :{" "}
                                <strong> {Rupiah(item.sidet_subtotal)} </strong>
                              </span>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          size="5"
                          className="ion-no-padding ion-no-margin"
                        ></IonCol>
                      </IonRow>
                    </IonCard>
                  ))
                ) : (
                  <IonCard>
                    <IonText className="ion-text-center">
                      <strong>
                        {t("invoice")} {t("not-found")}
                      </strong>
                    </IonText>
                  </IonCard>
                )}
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            {so.so_is_closed === 2 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 && (
                <IonFabButton
                  data-desc={`${t("edit")}`}
                  onClick={() => {
                    presentForm({
                      cssClass: "modal-fullscreen",
                    });
                  }}
                >
                  <IonIcon icon={createOutline} />
                </IonFabButton>
              )}
            {so.so_is_closed === 2 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              user?.user_role_id !== "SALES" && (
                <IonFabButton
                  data-desc={`${t("confirm")} ${t("module.customer")}`}
                  onClick={() => {
                    confirmCustomer();
                  }}
                >
                  <IonIcon icon={logoWhatsapp} color="success" />
                </IonFabButton>
              )}
            {so.so_is_closed === 0 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              user?.user_role_id !== "SALES" && (
                <IonFabButton
                  data-desc={`${t("edit")}`}
                  onClick={() => {
                    presentForm({
                      cssClass: "modal-fullscreen",
                    });
                  }}
                >
                  <IonIcon icon={createOutline} />
                </IonFabButton>
              )}
            {so.so_is_closed === 2 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              getRole?.role_menu_delete === 1 && (
                <IonFabButton
                  data-desc={`Proses ${t("check")}`}
                  onClick={() => {
                    processAlert(3, "check");
                  }}
                >
                  <IonIcon icon={checkmarkCircleOutline} />
                </IonFabButton>
              )}
            {so.so_is_closed === 3 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              getRole?.role_menu_delete === 1 &&
              getRole?.role_menu_print === 1 &&
              getRole?.role_menu_export === 1 && (
                <IonFabButton
                  data-desc={`Proses ${t("approve")}`}
                  onClick={() => {
                    processAlert(0, "approve");
                  }}
                >
                  <IonIcon icon={checkmarkCircleOutline} />
                </IonFabButton>
              )}
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Detail;
