import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  IonPage,
  useIonAlert,
  useIonModal,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonCardContent,
  IonRow,
  IonCol,
  IonCardHeader,
  IonRadioGroup,
  IonRadio
} from '@ionic/react';
import React, { useState} from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBackOutline, menuSharp, locateOutline, mapOutline, cashOutline, walletOutline ,documentOutline, cartOutline, storefrontSharp, starOutline} from "ionicons/icons";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import History from '../../components/visit/History';
import Ar from './Ar';
import BackOrder from './BackOrder';
import Payment from './Payment';
import Sales from './Sales';
import { RestApi } from '../../services/Api';
import { Rupiah, getNameDate } from '../../services/Library';
import Map from '../../components/Map';
import { Customer } from '../../models/Modal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectOption from '../input/SelectOption';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<Customer> = (props) =>{
  const [customer, setCustomer] = useState<any>([]);
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [onLoadInfo, setOnLoadInfo] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const [segment, setSegment] = useState<string>("information");
  const [address, setAddress] = useState<any>();
  const [showModalSelectOptionAddress, setShowModalSelectOptionAddress] = useState<boolean>(false);
  const [segmentImage, setSegmentImage] = useState<string>(
    "customer_identity_card_image"
  );
  let countTotal: number = 0;

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      getCustomerDetail(props.idCustomer)
      event.detail.complete();
  }

  const getCustomerDetail = (id:string) => {
    if(!id) {
      props.onDismissDetail()
      return;
    }
    setLoaded(false)
    RestApi(`customer/${id}`,'get',{},'application/json')
    .then(async(res:any) => {
        if (res.data.code === 200) {
          setCustomer(res.data.data)
          setLoaded(true)
        }else{
          props.onDismissDetail()
        }
    })
    .catch((err:any) => {
        props.onDismissDetail()
        massageAlert('error',err.message)
    });
  }
  
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP||''
  })

  const handleDismissDetail = (e:any) => {
    getCustomerDetail(props.idCustomer)
    dismissMap()
  }

  const handleDismissHistory = (e:any) => {
    dismissHistory()
  }

  const handleDismissAr = (e:any) => {
    dismissAr()
  }

  const handleDismissBackOrder = (e:any) => {
    dismissBackOrder()
  }

  const handleDismissPayment = (e:any) => {
    dismissPayment()
  }

  const handleDismissSales = (e:any) => {
    dismissSales()
  }
  
  const [presentMap, dismissMap] = useIonModal(Map, {
    customer: customer,
    type: "customer",
    visit: null,
    address: address,
    onDismissDetail: handleDismissDetail
  });

  const setValRadioAssessment = (val: any) => {
    return val.find((item: any) => item.selected === true);
  };


  const [presentHistory, dismissHistory] = useIonModal(History, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissHistory
  });

  const [presentAr, dismissAr] = useIonModal(Ar, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissAr
  });

  const [presentBackOrder, dismissBackOrder] = useIonModal(BackOrder, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissBackOrder
  });
  
  const [presentPayment, dismissPayment] = useIonModal(Payment, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissPayment
  });

  const [presentSales, dismissSales] = useIonModal(Sales, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissSales
  });

  const addressSelectionChanged = (idAddress: string[]) => {
    setAddress(
      customer.customer_address.find(
        (val: any) => val.customer_address_id === idAddress
      )
    );
    presentMap({
      cssClass: "modal-fullscreen",
    });
    return;
  };

  useConstructor(() => {
    getCustomerDetail(props.idCustomer)
  });
    
  return (
    <IonPage className="detail-content">
      <IonHeader>
        {loaded ? (
          <IonToolbar class="toolbar-detail-customer ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissDetail()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding" lines="none">
              <IonLabel className="ion-no-padding" color="light">
                <h4>
                  <strong>{customer.customer_name}</strong> (
                  {customer.customer_title})
                </h4>
                <span>Limit : {Rupiah(customer.customer_limit_credit)}</span>
              </IonLabel>
              <IonBadge
                slot="end"
                color={customer.customer_is_active ? "success" : "danger"}
              >
                {customer.customer_is_active ? "Aktif" : "InAktif"}
              </IonBadge>
            </IonItem>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {customer && loaded ? (
          <>
            <IonCard className="ion-no-padding">
              <IonSegment
                scrollable={true}
                value={segment}
                className="ion-no-padding ion-no-margin"
              >
                <IonSegmentButton
                  value="information"
                  onClick={() => {
                    setSegment("information");
                  }}
                >
                  <IonLabel>{t("information")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="address"
                  onClick={() => {
                    setSegment("address");
                  }}
                >
                  <IonLabel>{t("address")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="contact"
                  onClick={() => {
                    setSegment("contact");
                  }}
                >
                  <IonLabel>{t("contact")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="detail"
                  onClick={() => {
                    setSegment("detail");
                  }}
                >
                  <IonLabel>{t("detail")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="assessment"
                  onClick={() => {
                    setSegment("assessment");
                  }}
                >
                  <IonLabel>SPKO</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="map"
                  onClick={() => {
                    setSegment("map");
                  }}
                >
                  <IonLabel>{t("map")}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {segment === "information" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :<strong>{customer.customer_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Telpon :<strong>{customer.customer_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Fax :<strong>{customer.customer_fax}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :<strong> {customer.customer_email}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Status :
                      <strong>
                        {customer.customer_is_active ? "AKTIF" : "TIDAK AKTIF"}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />

                    <p className="ion-no-padding ion-no-margin">
                      Area :
                      <strong>
                        {customer.subarea_name} -{customer.area_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kota :<strong> {customer.customer_city}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Propinsi :<strong> {customer.customer_state}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Negara :<strong>{customer.customer_country}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kode Pos :<strong>{customer.customer_zipcode}</strong>
                    </p>
                  </IonText>
                )}
                {segment === "contact" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Nama Kontak :
                      <strong>{customer.customer_contact_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Tanggal Lahir :
                      <strong>
                        {getNameDate(customer.customer_contact_birthday)}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Jabatan :
                      <strong>{customer.customer_contact_title}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Hobby :<strong>{customer.customer_contact_hobby}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :
                      <strong>{customer.customer_contact_mobile}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :<strong>{customer.customer_contact_email}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama KTP :
                      <strong>{customer.customer_identity_card_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      No. KTP :
                      <strong>{customer.customer_identity_card_number}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama NPWP :<strong>{customer.customer_tax_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nomor NPWP :
                      <strong>{customer.customer_tax_number}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                  </IonText>
                )}
                {segment === "address" && (
                  <>
                    {customer.customer_address.map((address: any) => (
                      <IonCard
                        key={address.customer_address_id}
                        className="ion-margin"
                        style={{
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: "12px",
                        }}
                      >
                        <IonCardContent>
                          <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
                            <IonCol
                              size="6"
                              className="ion-no-padding ion-no-margin"
                            >
                              <IonBadge
                                color={
                                  address.customer_address_is_active === 1
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {address.customer_address_is_active === 1
                                  ? "Aktif"
                                  : "InAktif"}
                              </IonBadge>
                            </IonCol>
                            <IonCol
                              size="6"
                              className="ion-no-padding ion-no-margin ion-text-end"
                            >
                              {address.customer_address_is_default === 1 && (
                                <IonIcon
                                  icon={starOutline}
                                  color="warning"
                                  size="small"
                                />
                              )}
                            </IonCol>
                            <IonCol
                              size="12"
                              className="ion-no-padding ion-no-margin"
                            >
                              <IonText>
                                <h3
                                  style={{
                                    margin: 0,
                                    color: "#333",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {address.customer_address_name}
                                </h3>
                                <p style={{ color: "#666" }}>
                                  {address.customer_address}
                                </p>
                                <hr className="ion-no-padding ion-no-margin" />
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                )}
                {segment === "detail" && (
                  <>
                    <IonText className="ion-padding-start">
                      <p className="ion-no-padding ion-no-margin">
                        Website URL :<strong> {customer.customer_url}</strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                      <p className="ion-no-padding ion-no-margin">
                        Keterangan :<strong>{customer.customer_remark}</strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                    </IonText>
                  </>
                )}
                {segment === "map" && isLoaded && (
                  <GoogleMap
                    mapContainerClassName="google-map"
                    center={{
                      lat: Number(
                        customer.customer_lat !== "null" ||
                          customer.customer_lat !== ""
                          ? customer.customer_lat
                          : user.branchs.branch_lat
                      ),
                      lng: Number(
                        customer.customer_lng !== "null" ||
                          customer.customer_lng !== ""
                          ? customer.customer_lng
                          : user.branchs.branch_lng
                      ),
                    }}
                    zoom={17}
                    options={{
                      gestureHandling: "none",
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                      clickableIcons: false,
                      keyboardShortcuts: false,
                    }}
                  >
                    <Marker
                      icon={{
                        path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                        fillColor: "yellow",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "gold",
                        strokeWeight: 2,
                      }}
                      position={{
                        lat: Number(
                          customer.customer_lat !== "null" ||
                            !customer.customer_lat
                            ? customer.customer_lat
                            : user.branchs.branch_lat
                        ),
                        lng: Number(
                          customer.customer_lng !== "null" ||
                            !customer.customer_lng
                            ? customer.customer_lng
                            : user.branchs.branch_lng
                        ),
                      }}
                      onClick={() => setOnLoadInfo(!onLoadInfo)}
                    />

                    {onLoadInfo && (
                      <InfoWindow
                        onCloseClick={() => setOnLoadInfo(!onLoadInfo)}
                        position={{
                          lat: Number(
                            customer.customer_lat !== "null" ||
                              !customer.customer_lat
                              ? customer.customer_lat
                              : user.branchs.branch_lat
                          ),
                          lng: Number(
                            customer.customer_lng !== "null" ||
                              !customer.customer_lng
                              ? customer.customer_lng
                              : user.branchs.branch_lng
                          ),
                        }}
                      >
                        <IonText className="ion-no-padding">
                          <h4>
                            <IonIcon
                              size="small"
                              className="ion-no-padding"
                              src={storefrontSharp}
                            />
                            <strong>{customer.customer_name}</strong>
                          </h4>
                          <p className="ion-no-padding ion-no-margin">
                            <strong>{customer.customer_address}</strong>
                          </p>
                          <hr className="ion-no-padding ion-no-margin" />
                        </IonText>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                )}
                {segment === "assessment" && (
                  <IonCard className="ion-no-padding ion-no-margin">
                    <IonCardHeader className="ion-no-padding ion-no-margin">
                      <IonItem>
                        <IonText>
                          <strong>SPKO</strong>
                        </IonText>
                        <IonText slot="end">
                          <strong>Total</strong>
                        </IonText>
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding ion-no-margin">
                      {customer.assessment.map(
                        (valAsType: any, keyAsType: number) => {
                          let countSubTotal = 0;
                          return (
                            <IonCard key={keyAsType}>
                              <IonCardHeader className="ion-no-padding ion-no-margin">
                                <IonText>
                                  <strong>
                                    {valAsType.assessment_type_name}
                                  </strong>
                                </IonText>
                              </IonCardHeader>
                              <IonCardContent>
                                {valAsType.assessment.map(
                                  (valAs: any, keyAs: number) => {
                                    return (
                                      <div key={keyAs}>
                                        <IonItem className="ion-no-padding ion-no-margin">
                                          <IonText>
                                            {valAs.assessment_name}
                                          </IonText>
                                          <IonText slot="end">
                                            {valAs.assessment_weight}
                                          </IonText>
                                        </IonItem>
                                        <IonList>
                                          <IonRadioGroup
                                            value={setValRadioAssessment(
                                              valAs.assessment_value
                                            )}
                                          >
                                            {valAs.assessment_value.map(
                                              (value: any, key: number) => {
                                                return (
                                                  <IonItem
                                                    className="ion-no-padding ion-no-margin"
                                                    key={key}
                                                    lines="none"
                                                  >
                                                    <IonRadio
                                                      disabled={true}
                                                      className="ion-no-padding ion-no-margin"
                                                      value={value}
                                                      justify="space-between"
                                                    >
                                                      <strong
                                                        className="ion-text-wrap"
                                                        style={{
                                                          color: "strong",
                                                        }}
                                                      >
                                                        {value.name} (
                                                        {value.weight})
                                                      </strong>
                                                    </IonRadio>
                                                  </IonItem>
                                                );
                                              }
                                            )}
                                          </IonRadioGroup>
                                        </IonList>
                                        <IonItem>
                                          <IonText slot="end">
                                            {(() => {
                                              let selected =
                                                setValRadioAssessment(
                                                  valAs.assessment_value
                                                );
                                              let total = Math.round(
                                                selected
                                                  ? Number(selected.weight) *
                                                      valAs.assessment_weight
                                                  : 0
                                              );
                                              countSubTotal += total;
                                              countTotal += total;
                                              return total?.toFixed(1);
                                            })()}
                                          </IonText>
                                        </IonItem>
                                      </div>
                                    );
                                  }
                                )}
                              </IonCardContent>
                              <IonItem className="ion-no-padding ion-no-margin">
                                <IonText slot="end">
                                  <strong>
                                    Sub Total = {countSubTotal?.toFixed(1)}
                                  </strong>
                                </IonText>
                              </IonItem>
                            </IonCard>
                          );
                        }
                      )}
                    </IonCardContent>
                    <IonItem className="ion-no-padding ion-no-margin">
                      <IonText slot="end" class="ion-text-end">
                        <p className="ion-no-padding ion-no-margin">
                          <strong>Total = {countTotal?.toFixed(1)}</strong>
                        </p>
                        <hr className="ion-no-padding ion-no-margin" />
                        <IonText color="primary">
                          <p className="ion-no-padding ion-no-margin">
                            {(() => {
                              if (customer.setting[1]) {
                                let setting_json_index_assessment = JSON.parse(
                                  customer.setting[1].settings_value
                                );
                                const itemInRange =
                                  setting_json_index_assessment.sort(
                                    (a: any, b: any) =>
                                      Number(b.attaintement_rate) -
                                      Number(a.attaintement_rate)
                                  );
                                const item = itemInRange.find(
                                  (item: any) =>
                                    countTotal >= item.attaintement_rate
                                );
                                return `${item.class} - (${item.category}), ${item.decision}`;
                              }
                            })()}
                          </p>
                          <hr className="ion-no-padding ion-no-margin" />
                        </IonText>
                      </IonText>
                    </IonItem>
                    {customer.setting[0] && (
                      <IonItem className="ion-no-padding ion-no-margin">
                        <div
                          className="ion-no-padding ion-no-margin"
                          dangerouslySetInnerHTML={{
                            __html: customer.setting[0].settings_value,
                          }}
                        ></div>
                      </IonItem>
                    )}
                  </IonCard>
                )}
              </IonCardContent>
            </IonCard>
            <IonCard className="ion-no-padding">
              <IonTitle className="ion-padding-start ion-no-margin">
                <strong>Foto Pelanggan</strong>
              </IonTitle>
              <IonCardContent className="ion-no-padding ion-no-margin">
                <IonSegment
                  scrollable={true}
                  value={segmentImage}
                  className="ion-no-padding ion-no-margin"
                >
                  <IonSegmentButton
                    value="customer_identity_card_image"
                    onClick={() => {
                      setSegmentImage("customer_identity_card_image");
                    }}
                  >
                    <IonLabel>KTP</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_image1"
                    onClick={() => {
                      setSegmentImage("customer_image1");
                    }}
                  >
                    <IonLabel>Foto 1</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_image2"
                    onClick={() => {
                      setSegmentImage("customer_image2");
                    }}
                  >
                    <IonLabel>Foto 2</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_image3"
                    onClick={() => {
                      setSegmentImage("customer_image3");
                    }}
                  >
                    <IonLabel>Foto 3</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_image4"
                    onClick={() => {
                      setSegmentImage("customer_image4");
                    }}
                  >
                    <IonLabel>Foto 4</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonImg
                  src={
                    customer[segmentImage] ??
                    `assets/img/image_not_available.png`
                  }
                />
              </IonCardContent>
            </IonCard>
            <SelectOption
              title={`${t("address")} ${t("module.customer")}`}
              items={customer.customer_address.map((value: any) => ({
                value: value.customer_address_id,
                text: `${value.customer_address_name}, ${value.customer_address}`,
              }))}
              selectedItems={[]}
              onSelectionCancel={() =>
                setShowModalSelectOptionAddress(!showModalSelectOptionAddress)
              }
              onSelectionChange={addressSelectionChanged}
              multiple={false}
              isOpen={showModalSelectOptionAddress}
            />
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton
              data-desc="Lokasi"
              onClick={() => {
                setShowModalSelectOptionAddress(true);
              }}
            >
              <IonIcon icon={locateOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="History Visit"
              onClick={() => {
                presentHistory({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={mapOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Daftar Piutang"
              onClick={() => {
                presentAr({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={documentOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Back Order"
              onClick={() => {
                presentBackOrder({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={cartOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Pembayaran"
              onClick={() => {
                presentPayment({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={cashOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Penjualan"
              onClick={() => {
                presentSales({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={walletOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Detail;
